.under-construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;
  }
  
  .message-box {
    text-align: center;
    background: white;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .message-box h1 {
    color: #e30613;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .message-box p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #555;
  }
  
  .password-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.9em;
    color: #e30613;
    text-decoration: none;
  }
  