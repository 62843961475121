.sub-menu-container {
    background-color: #f9f9f9;  /* Světle šedá barva pozadí */
    padding: 15px 0;
    border-bottom: 1px solid #ddd; /* Oddělení od obsahu */
  }
  
  .sub-menu ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sub-menu ul li {
    margin: 0 20px; /* Větší mezery mezi položkami */
  }
  
  .sub-menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 600; /* Ztučnění textu */
    padding: 8px 15px;
    transition: color 0.3s ease, background-color 0.3s ease, border-radius 0.3s ease;
    border-radius: 8px; /* Mírně zaoblené hrany pro jemný vzhled */
  }
  
  .sub-menu ul li a:hover {
    color: #fff; /* Bílé písmo při hoveru */
    background-color: #e30613; /* Červené pozadí při hoveru */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Jemný stín */
    border-radius: 8px;
  }
  
  .sub-menu ul li a:focus {
    outline: none; /* Odstranění ohraničení při focusu */
    background-color: #e30613;
    color: #fff;
  }
  