body {
  background-color: #fff; /* Bílé pozadí za kontejnerem */
}

.about-container {
  background-color: #fff; /* Bílé pozadí kontejneru */
  padding: 40px 20px;
  width: 100%; /* Roztažení na celou šířku */
  box-sizing: border-box; /* Zajistí, že padding se počítá do šířky */
}

.about {
  max-width: 1200px; /* Maximální šířka obsahu uvnitř kontejneru */
  margin: 0 auto; /* Vystředění obsahu */
  padding: 20px;
  background-color: #fff; /* Bílé pozadí uvnitř kontejneru */
  border-radius: 0; /* Odebrání zaoblení rohů */
  box-shadow: none; /* Odebrání stínu */
}

.about h1 {
  color: #e30613; /* Červená barva */
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.about p {
  color: #333; /* Černá barva */
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

.about ul {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  display: flex;
  justify-content: space-around;
}

.about ul li {
  background-color: #e30613; /* Červená barva */
  color: #fff;
  font-size: 1.1em;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: bold;
}

.about h2 {
  color: #e30613; /* Červená barva */
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .about ul {
    flex-direction: column;
    align-items: center;
  }

  .about ul li {
    margin-bottom: 10px;
  }
}
