.top-bar {
    background-color: #333;  /* Tmavě šedá barva pozadí */
    color: white;  /* Bílé písmo */
    padding: 0;  /* Žádné vnitřní odsazení */
    font-size: 0.8em;  /* Snížení velikosti písma */
    font-weight: bold;  /* Tučné písmo */
    width: 100%;  /* Zajištění, že proužek zabírá celou šířku */
    display: flex;
    justify-content: flex-end;  /* Zarovnání obsahu na pravou stranu */
    align-items: center;  /* Vertikální zarovnání obsahu na střed */
    box-sizing: border-box;  /* Zajištění, že padding nebude přidán k šířce elementu */
    position: fixed; /* Fixní umístění při scrolování */
    top: 0;
    z-index: 1000; /* Zajištění, že proužek bude nad ostatními elementy */
  }
  
  .top-bar .container {
    width: 100%;  /* Zajistí, že container zabírá celou šířku */
    padding-right: 10px;  /* Trochu odsazení od pravého okraje */
    display: flex;
    justify-content: flex-end;  /* Zarovnání obsahu na pravou stranu */
    margin: 0;  /* Zrušení defaultního odsazení */
    box-sizing: border-box;  /* Ujistí se, že padding bude počítán do šířky */
  }
  
  .top-bar p {
    margin: 0;  /* Zrušení defaultního marginu na odstavci */
    display: flex;
    align-items: center; /* Zarovnání ikon a textu vertikálně na střed */
  }
  
  .top-bar svg {
    margin-right: 8px;  /* Odsazení ikon od textu */
    font-size: 1.2em;  /* Velikost ikon, můžete upravit podle potřeby */
  }
  
  .top-bar p span {
    margin-right: 15px;  /* Menší mezera mezi telefonním číslem a e-mailem */
  }
  