.fotovoltaika-container {
    width: 100%;
    padding: 40px 20px;
    background-color: #fff;
  }
  
  .fotovoltaika-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .fotovoltaika-content h1 {
    color: #e30613;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .fotovoltaika-content p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
  }
  
  .fotovoltaika-content h2 {
    color: #e30613;
    font-size: 1.5em; /* Nastaveno na 1.5em */
    margin-bottom: 10px;
  }
  
  .fotovoltaika-content ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .fotovoltaika-content ul li {
    font-size: 1.1em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .fotovoltaika-content ul li strong {
    color: #e30613;
    font-weight: bold;
  }
  