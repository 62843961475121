.footer {
    background-color: #e30613;
    color: white;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #e0e0e0;
    width: 100%;
    margin-top: auto;
  }
  
  .footer .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  