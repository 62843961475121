.contact-container {
  width: 100%;
  padding: 80px 20px; /* Zvýšení paddingu pro více prostoru kolem obsahu */
  background-color: #fff; /* Bílé pozadí */
}

.contact {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 30px;
  border-radius: 8px;
  background-color: #ffffff; /* Lehce šedé pozadí uvnitř sekce pro kontrast */
}

.contact-details {
  flex: 1;
  margin-right: 40px; /* Zvýšení mezery mezi detaily a mapou */
}

.contact-details h2 {
  color: #e30613;
  font-size: 2em; /* Zvýšení velikosti nadpisu */
  margin-bottom: 15px;
  font-weight: bold; /* Zvýraznění nadpisu */
}

.contact-details p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 10px 0; /* Zvětšení mezery mezi odstavci */
  color: #333; /* Změna barvy textu pro lepší čitelnost */
}

.contact-details .section-break {
  margin-top: 40px; /* Zvýšení mezery mezi sekcemi Sídlo firmy a Kancelář a sklad */
}

.contact-details p svg {
  margin-right: 10px;
  color: #e30613; /* Zachování červené barvy ikon */
}

.contact-map {
  flex: 1;
  background-color: #ddd; /* Zástupná barva pro mapu */
  height: 400px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .contact {
    flex-direction: column;
  }

  .contact-details {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-map {
    height: 300px;
  }
}
