.home-container {
    width: 100%;
  }
  
  .home-banner {
    background-color: #e30613;
    color: white;
    padding: 40px 20px;
    text-align: center;
    width: 100%;
  }
  
  .home-banner .content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-banner h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .home-banner p {
    font-size: 1.2em;
    line-height: 1.5;
  }
  
  .home {
    background-color: #fff;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  