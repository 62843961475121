.services-container {
    width: 100%;
    padding: 60px 20px;
    background-color: #fff;
  }
  
  .services {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .services h1 {
    color: #e30613;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .services p {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 20px 0;
    color: #555;
  }
  
  .services ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    display: inline-block;
  }
  
  .services ul li {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 10px 0;
    color: #333;
  }
  
  .services ul li::before {
    content: "•";
    color: #e30613;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  