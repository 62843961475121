.carousel-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
}

.carousel {
  display: flex;
  flex-wrap: wrap; /* Umožňuje obtékání */
  justify-content: center; /* Zarovnání obsahu na střed */
  gap: 20px; /* Vytvoření rovnoměrného prostoru mezi rámečky */
}

.product-card {
  background-color: white;
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  width: 220px; /* Pevná šířka karet */
  height: 300px; /* Pevná výška karet */
  margin: 20px 0px; /* Prostor okolo každého rámečku */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
  text-decoration: none; /* Odstranění podtržení odkazů */
  color: inherit; /* Zachování barvy textu */
}

.product-card img {
  width: 100%;
  height: 150px; /* Pevná výška obrázků */
  object-fit: cover; /* Zajišťuje, že obrázek se přizpůsobí bez deformace */
  object-position: top; /* Zarovnání obrázku na horní část */
  border-bottom: 2px solid #dcdcdc;
  margin-bottom: 10px;
}


.product-card h3 {
  margin: 10px 0;
  font-size: 1.2em;
  min-height: 40px; /* Zajištění stejné výšky nadpisu */
}

.product-card p {
  color: #555;
  font-size: 0.9em;
  line-height: 1.4;
  flex-grow: 1; /* Zajištění, že textová část vyplní zbývající prostor */
}

.product-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Jemný stín při hoveru */
}
