* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  margin-top: 50px;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

header, footer {
  width: 100%;
}

.header {
  margin-top: 50px; /* Přidání stejného odsazení pro hlavní menu */
}