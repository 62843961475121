.password-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .message-box {
    text-align: center;
    background: white;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .message-box h1 {
    color: #e30613;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .message-box form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .message-box input {
    padding: 10px;
    font-size: 1em;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 200px;
  }
  
  .message-box button {
    background-color: #e30613;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .message-box button:hover {
    background-color: #c00511;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  