.header {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo img {
  height: 50px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav ul li {
  margin: 0 15px;
}

.nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold; /* Změna font-weight na bold */
}

.nav ul li a:hover {
  color: #e30613;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responzivní stylování */
@media (max-width: 768px) {
  .nav {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    display: none;
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    flex-direction: column;
  }

  .nav ul li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
}
