* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}
